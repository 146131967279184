import { crmGenerated } from './generated/crm';


export const crm = crmGenerated.enhanceEndpoints({
  addTagTypes: ['Contact', 'Organization'],
  endpoints: {
    appCrmContactFieldChoicesCreate(endpoint) {
      endpoint.invalidatesTags = (res, err, arg) => [{ type: 'ContactFieldTemplate', id: arg.contact_field_template }];
    },
    appCrmCompaniesPartialUpdate: (endpoint) => {
      if (endpoint.query) {
        endpoint.query = (arg) => {
          // when logo is being updated we want to send multipart form-data instead of JSON
          if (arg.patchedCompany.logo) {
            const formData = new FormData();
            formData.append('logo', arg.patchedCompany.logo);
            return {
              url: `/api/v1_15/app-crm/companies/${arg.id}/`,
              method: 'PATCH',
              body: formData,
            };
          }

          return {
            url: `/api/v1_15/app-crm/companies/${arg.id}/`,
            method: 'PATCH',
            body: arg.patchedCompany,
          };
        };
      }
    },
  },
  // TODO: cache normalization using tags
  // endpoints: Object.fromEntries(
  //   Object.entries(crmGenerated.endpoints).map(([key, endpoint]) => {
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     const enhancedEndpoint = (ept: EndpointDefinition<unknown, () => any, string, unknown>) => {
  //       if (key.split('apiLatestAppCrmContactsJson')[1] === 'Create') {

  //       }
  //       // ept.transformResponse = (res) => {
  //       //   // check the tags and the operation (from the end of the key) to determine what entity adapter
  //       //   // method to call (create, update, etc.)
  //       //   // return {}
  //       // }
  //       // we don't want to keep the default generated tags right now
  //       if (ept.providesTags?.length) ept.providesTags = [];
  //       if (ept.invalidatesTags?.length) ept.invalidatesTags = [];
  //       // ept.serializeQueryArgs = ({ queryArgs, endpointDefinition, endpointName }) => {
  //       //   return 'newCacheKey';
  //       // };
  //     };
  //     return [key, enhancedEndpoint];
  //   })
  // ),
  // endpoints: {
  //   apiLatestAppCoreLeadersList(endpoint) {
  //     // endpoint.providesTags = ['Leader'];
  //     // endpoint.invalidatesTags = (result, err, arg) => [{ type: 'Leader', id: arg.id }];
  //     // endpoint.keepUnusedDataFor = 120;
  //     endpoint.transformResponse = () => {
  //       // transform response here
  //       return { results: [] };
  //     };
  //   },
  // },
});
